import { useRouter } from "next/router";
import Script from "next/script";

export const PodiumScript = () => {
  const router = useRouter();
  if (router.pathname.startsWith("/admin")) {
    return null;
  }
  return (
    <Script
      defer
      src="https://connect.podium.com/widget.js#ORG_TOKEN=54e1f4ab-1320-45c7-bd53-1a4580d4146c"
      id="podium-widget"
      data-organization-api-token="54e1f4ab-1320-45c7-bd53-1a4580d4146c"
      strategy="lazyOnload"
    />
  );
};
